import React from "react";
import "../../Master/Tabels/Tabels.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import plus from "../../assets/icons/PLUS.png";
import colunms from "../../assets/icons/LINES.png";
import pen from "../../assets/icons/pen.png";
import basket from "../../assets/icons/basket.png";
import search1 from "../../assets/icons/search.png";
import top from "../../assets/icons/top.png";
import blackeye from "../../assets/icons/blackeye.png";
import whiteimg from "../../assets/icons/eye.png";
import { Context } from "../../../../utils/context";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { postData } from "../../../../utils/api";
import axios from "axios";
library.add(fas);
const DataTable = () => {
  const { getData, editStatusData, deleteData, ErrorNotify } =
    useContext(Context);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();

  const getDataAgencyName = async () => {
    const response = await getData(
      `/reports/dataentry/getallproperty?page=${currentPage}&per_page=${perPage}&term=${search}`
    );

    // console.log(response);

    const a = [];
    for (let i = 5; i <= response?.data?.totalEntries; i *= 2) {
      a.push(i);
    }
    a.push(response?.data?.totalEntries);

    setOption(a);

    // console.log(response?.data);
    await setData(response?.data);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);

    const newData = response?.data?.result;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;
    // console.log(value);
    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        // Make an API request to download the Excel file
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/api/dataentryheadbillsdownload",
          selectAllChecked,
          {
            responseType: "blob", // Specify the response type as 'blob' for binary data
          }
        );

        // Create a URL for the Excel file data
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloaded_file.xlsx"); // Specify the file name
        document.body.appendChild(link);
        link.click();

        // Clean up after the download
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // console.log(selectAllChecked);

  // Delete module
  // const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  // const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  // const showDeleteRecord = async (id, name) => {
  //   setShowDeleteModal(true);
  //   setRecordToDeleteId(id);
  //   setRecordToDeleteName(name);
  // };

  // const handleDeleteRecord = async () => {
  //   setShowDeleteModal(false);
  //   if (recordToDeleteId) {
  //     const response = await deleteData(
  //       `/master/taskmaster/agencyname/deleteagencyname/${recordToDeleteId}`
  //     );
  //     setRecordToDeleteId(null);
  //     // console.log(response);
  //     if (response.success) {
  //       ErrorNotify(recordToDeleteName);
  //       setRecordToDeleteName(null);
  //       setChangeStatus(response);
  //     }
  //     // await getDataAgencyName();
  //   }
  // };

  // const handleDeleteCancel = () => {
  //   setShowDeleteModal(false);
  //   setRecordToDeleteId(null);
  //   setRecordToDeleteName(null);
  // };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [formData, setFormData] = useState({ id: "", choice: "", remarks: "" });

  const handleChange1 = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log([e.target.name] + "=" + e.target.value);
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  // const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, choice) => {
    setShowDeleteModal(true);
    setFormData({ ...formData, id: id, choice: choice });
    // setRecordToDeleteId(id);
    // setRecordToDeleteName(choice);
  };
  const handleDeleteRecord = async () => {
    if (
      formData.id !== "" &&
      formData.choice !== "" &&
      formData.remarks !== ""
    ) {
      // console.log(formData);
      const response = await postData(
        `/reports/dataentry/postdataentryhead`,
        formData
      );
      // `/master/taskmaster/agencyname/deleteagencyname/${recordToDeleteId}`
      // setRecordToDeleteId(null);
      // console.log(response);
      if (response.success) {
        setShowDeleteModal(false);
        setFormData({ ...formData, id: "", choice: "", remarks: "" });
        // ErrorNotify(recordToDeleteName);
        // setRecordToDeleteName(null);
        // setChangeStatus(response);
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setFormData({ ...formData, id: "", choice: "", remarks: "" });
    // setRecordToDeleteId(null);
    // setRecordToDeleteName(null);
  };

  useEffect(() => {
    getDataAgencyName();
  }, [reset, showDeleteModal]);

  return (
    <>
      <div className="col-xxl-12 col-xl-12">
        <section className="Tabels tab-radio mt-4">
          <div className="container">
            <div className="row">
              <div className="d-flex">
                <div className="add me-3">
                  {/* <Link to="">
                    <button type="button" className="btn btn-add pe-3">
                      {" "}
                      <img src={plus} className="plus me-2 ms-0" alt="" /> Add
                      Tax{" "}
                    </button>
                  </Link> */}
                </div>

                <div className="add">
                  <div className="dropdown">
                    <button
                      className="btn btn-columns dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {" "}
                      <img src={colunms} className="columns " alt="" /> Column
                      Selection <img src={top} className="top ms-1" alt="" />{" "}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col0")}
                          href="#"
                        >
                          Check Boxs
                          {visible?.col0 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col1")}
                          href="#"
                        >
                          Sr. No.
                          {visible?.col1 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col8")}
                          href="#"
                        >
                          Property No.
                          {visible?.col8 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col2")}
                          href="#"
                        >
                          Owner Name
                          {visible?.col2 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col3")}
                          href="#"
                        >
                          Property Type
                          {visible?.col3 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col4")}
                          href="#"
                        >
                          Ward
                          {visible?.col4 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col5")}
                          href="#"
                        >
                          Address
                          {visible?.col5 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon 
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          onClick={(event) => toggleColumn(event, "col6")}
                          href="#"
                        >
                          Active
                          {visible?.col6 ? (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye"
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="ms-4 eye-icon-img"
                              icon="fa-solid fa-eye-slash"
                            />
                          )}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="view-details ">
                  {/* <Link to=""> */}{" "}
                  <button className="btn btn-view" onClick={HandleDownload}>
                    <FontAwesomeIcon
                      icon="fa-solid fa-download"
                      className="me-2"
                    />
                    Download
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
            <div className="border-line mt-3"></div>
            <div className="row mt-3">
              <div className="data table-responsive">
                <table className="table table-bordered  tableborder">
                  <thead>
                    <tr className="">
                      {visible.col0 && (
                        <th className="check round-check">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="selectAll"
                              checked={
                                allChecked.length === selectAllChecked.length
                              }
                              onChange={handleChange}
                              id="selectAll"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            ></label>
                          </div>
                        </th>
                      )}
                      {visible.col1 && <th className="sr">Sr. No.</th>}
                      {visible.col8 && <th className="sr">Property No.</th>}
                      {visible.col2 && <th className="tax-name">Owner Name</th>}
                      {visible.col3 && <th className="rate">Property Type</th>}
                      {visible.col4 && <th className="active">Ward </th>}
                      {visible.col5 && <th className="active">Address</th>}
                      {visible.col6 && <th className="active">Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.result?.map((d, index) => {
                      return (
                        <tr className="" key={index}>
                          {visible.col0 && (
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={d?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(d?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                ></label>
                              </div>
                            </td>
                          )}
                          {visible.col1 && <td>{++index}</td>}
                          {visible.col8 && <td>{d.property_no}</td>}
                          {visible.col2 && (
                            <td>
                              {d.last_name +
                                " " +
                                d.first_name +
                                " " +
                                d.mid_name}{" "}
                            </td>
                          )}

                          {visible.col3 && <td>{d?.property_type?.name}</td>}

                          {visible.col4 && (
                            <td className="rate">{d?.ward_id?.name}</td>
                          )}
                          {visible.col5 && <td>{d?.address}</td>}
                          {visible.col6 && (
                            <td>
                              <div className="d-flex">
                                <div className="me-2">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      showDeleteRecord(d?.id, 1);
                                    }}
                                    className="btn btn-success "
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-check" />
                                  </button>
                                </div>

                                <div className="me-2">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      navigate(`/data-entry-view/${d?.id}`);
                                    }}
                                    className="btn btn-primary"
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-eye" />
                                  </button>
                                </div>

                                <div className="me-2">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      showDeleteRecord(d?.id, 2);
                                    }}
                                    className="btn btn-danger"
                                  >
                                    <FontAwesomeIcon icon="fa-solid fa-registered" />
                                  </button>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {data?.result?.length === 0 ||
                data?.result?.length === undefined ? (
                  <div className="text-center">
                    <h4>No Records Found</h4>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row mt-4">
              <div className="  col-lg-4 col-md-4 col-12">
                <div className="d-flex">
                  <div className="show me-2">
                    <p className="show mt-2">Show</p>
                  </div>
                  <div className="number me-2">
                    <select
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                      onChange={(e) => setperPage(e.target.value)}
                    >
                      {option?.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="entries">
                    <p className="show mt-2">entries</p>
                  </div>
                </div>
              </div>
              <div className="  col-lg-8  col-md-8 col-12">
                <div className="d-flex justify-content-end">
                  <div className="sowing me-2">
                    <p className="show mt-2">{`Showing ${Math.min(
                      (currentPage - 1) * perPage + 1
                    )} to ${Math.min(
                      currentPage * perPage,
                      data?.totalEntries
                    )} of ${data?.totalEntries} entries`}</p>
                  </div>
                  <div className="num me-2">
                    <input
                      type="number"
                      className="form-control"
                      id=""
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                  <div className="Search me-2">
                    <button
                      type="button"
                      onClick={getDataAgencyName}
                      className="btn btn-search"
                    >
                      <img src={search1} className="search" alt="" />
                    </button>
                  </div>
                  <div className="Search-1">
                    <button
                      type="button"
                      onClick={() => {
                        setSearch("");
                        setReset(!reset);
                      }}
                      className="btn btn-reset"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">Remarks</h1>
                <p>
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  ></label>
                  <div className="ms-5  me-5">
                    <input
                      type="text"
                      name="remarks"
                      placeholder={`Enter remarks`}
                      value={formData.remarks}
                      onChange={handleChange1}
                      className="form-control"
                      id="inputEmail3"
                    />
                  </div>
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable;
