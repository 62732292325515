import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "./PageNotFound";
// Data Entry
import HeaderDataEntry from "./Components/DataEntry/Header/Header";
import GeneratedBill from "./Components/DataEntry/GeneratedBill/GeneratedBill";
import Login from "./Components/DataEntry/Login/Login";
import BillView from "./Components/DataEntry/Mobile-View/BillView/BillView";
import BillHistory from "./Components/DataEntry/Mobile-View/BillHistory/History.js";
import Form from "./Components/DataEntry/Mobile-View/Form/Form.js";
import HistoryView from "./Components/DataEntry/HistoryPage/HistoryView/HistoryView";

// Super Admin
import HeaderSuperAdmin from "./Components/SuperAdmin/Header/Header";
import DashBoard from "./Components/SuperAdmin/DashBoard/DashBoard";
import TaxMasterList from "./Components/SuperAdmin/Master/Tax-master/List";
import TaxmasterAdd from "./Components/SuperAdmin/Master/Tax-master/Add";
import TaxmasterEdit from "./Components/SuperAdmin/Master/Tax-master/Edit";
import TaxList from "./Components/SuperAdmin/Master/Tax/List";
import TaxAdd from "./Components/SuperAdmin/Master/Tax/Add";
import TaxEdit from "./Components/SuperAdmin/Master/Tax/Edit";
import Subtaxlist from "./Components/SuperAdmin/Master/sub-tax-master/List";
import CustomerList from "./Components/SuperAdmin/Master/customer-master/List";
import DepartmentList from "./Components/SuperAdmin/Master/department/List";
import Documentmasterlist from "./Components/SuperAdmin/Master/document-master/List";
import Finemasterlist from "./Components/SuperAdmin/Master/fine-master/List";
import Noticemasterlist from "./Components/SuperAdmin/Master/notice-master/List";
import Paymentremindermasterlist from "./Components/SuperAdmin/Master/payment-reminder-master/List";
import Subtaxmasterlist from "./Components/SuperAdmin/Master/sub-tax-master/List";
import View from "./Components/SuperAdmin/Master/customer-master/View";
import Upload from "./Components/SuperAdmin/Master/document-master/Upload";
import Dataentry from "./Components/SuperAdmin/report-folder/data-entry/Dataentry";
import DataentryApproved from "./Components/SuperAdmin/report-folder/data-entry-approved/Dataentry";
import DataentryRejected from "./Components/SuperAdmin/report-folder/data-entry-rejected/Dataentry";
import DataentryView from "./Components/SuperAdmin/report-folder/views/Dataentry";
import WardWise from "./Components/SuperAdmin/report-folder/ward-wise/WardWise";
import Assesment from "./Components/SuperAdmin/report-folder/assesment/Assesment";
import Deletion from "./Components/SuperAdmin/report-folder/deletion/Deletion";
import Counterwise from "./Components/SuperAdmin/collection/counterwise/Counterwise";
import Company from "./Components/SuperAdmin/setting/company-name/Company";
import UserDetails from "./Components/SuperAdmin/setting/user-details/UserDetails";
import Online from "./Components/SuperAdmin/setting/online-payment/Online";
import TaxRates from "./Components/SuperAdmin/setting/Tax-rate/TaxRates";
import Product from "./Components/SuperAdmin/setting/product/Product";
import Notification from "./Components/SuperAdmin/setting/notification/Notification";
import TaxRegister from "./Components/SuperAdmin/report-folder/Tax-register/TaxRegister";
import Govt from "./Components/SuperAdmin/report-folder/Govt-tax/Govt";
import Localization from "./Components/SuperAdmin/setting/Localisation/Localization";
import Export from "./Components/SuperAdmin/setting/export/Export";
import AccountManager from "./Components/SuperAdmin/setting/account-manager/AccountManager";

import Rolemanagement from "./Components/SuperAdmin/rolemanagement/rolemanagement/Rolemanagement";
import RolemanagementAdd from "./Components/SuperAdmin/rolemanagement/rolemanagement/Add";
import RolemanagementEdit from "./Components/SuperAdmin/rolemanagement/rolemanagement/Edit";

import Usersmanagement from "./Components/SuperAdmin/rolemanagement/usersmanagement/Usersmanagement";
import UsersmanagementAdd from "./Components/SuperAdmin/rolemanagement/usersmanagement/Add";
import UsersmanagementEdit from "./Components/SuperAdmin/rolemanagement/usersmanagement/Edit";

import CustomerMasterAdd from "./Components/SuperAdmin/Master/customer-master/Add";
import CustomerMasterEdit from "./Components/SuperAdmin/Master/customer-master/Edit";
import CustomerMasterList from "./Components/SuperAdmin/Master/customer-master/List";

import Billingstructure from "./Components/SuperAdmin/Master/Tax-master/billing_structure/Billingstructure";
import BillingstructureAdd from "./Components/SuperAdmin/Master/Tax-master/billing_structure/Add";
import BillingstructureEdit from "./Components/SuperAdmin/Master/Tax-master/billing_structure/Edit";
import Floor from "./Components/SuperAdmin/Master/Tax-master/floor/Floor.js";
import FloorAdd from "./Components/SuperAdmin/Master/Tax-master/floor/Add";
import FloorEdit from "./Components/SuperAdmin/Master/Tax-master/floor/Edit";
import Landtype from "./Components/SuperAdmin/Master/Tax-master/land_type/Land_type";
import LandtypeAdd from "./Components/SuperAdmin/Master/Tax-master/land_type/Add";
import LandtypeEdit from "./Components/SuperAdmin/Master/Tax-master/land_type/Edit";
import Landusetype from "./Components/SuperAdmin/Master/Tax-master/land_use_type/Land_use_type";
import LandusetypeAdd from "./Components/SuperAdmin/Master/Tax-master/land_use_type/Add";
import LandusetypeEdit from "./Components/SuperAdmin/Master/Tax-master/land_use_type/Edit";
import Agencyname from "./Components/SuperAdmin/Master/Tax-master/agency_name/Agency_name";
import AgencynameAdd from "./Components/SuperAdmin/Master/Tax-master/agency_name/Add";
import AgencynameEdit from "./Components/SuperAdmin/Master/Tax-master/agency_name/Edit";
import Licencetype from "./Components/SuperAdmin/Master/Tax-master/licence_type/Licence_type";
import LicencetypeAdd from "./Components/SuperAdmin/Master/Tax-master/licence_type/Add";
import LicencetypeEdit from "./Components/SuperAdmin/Master/Tax-master/licence_type/Edit";
import LicencetypeBulk from "./Components/SuperAdmin/Master/Tax-master/licence_type/Bulkupload";

import Country from "./Components/SuperAdmin/Master/Tax-master/country/Country";
import AddCountry from "./Components/SuperAdmin/Master/Tax-master/country/AddCountry";
import EditCountry from "./Components/SuperAdmin/Master/Tax-master/country/EditCountry";

import State from "./Components/SuperAdmin/Master/Tax-master/state/State";
import EditState from "./Components/SuperAdmin/Master/Tax-master/state/EditState";
import AddState from "./Components/SuperAdmin/Master/Tax-master/state/AddState";

import District from "./Components/SuperAdmin/Master/Tax-master/district/District";
import AddDistrict from "./Components/SuperAdmin/Master/Tax-master/district/AddDistrict";
import EditDistrict from "./Components/SuperAdmin/Master/Tax-master/district/EditDistrict";

import Zone from "./Components/SuperAdmin/Master/Tax-master/zone/Zone";
import EditZone from "./Components/SuperAdmin/Master/Tax-master/zone/EditZone";
import AddZone from "./Components/SuperAdmin/Master/Tax-master/zone/ZoneAdd";

import City from "./Components/SuperAdmin/Master/Tax-master/city/City";
import AddCity from "./Components/SuperAdmin/Master/Tax-master/city/AddCity";
import EditCity from "./Components/SuperAdmin/Master/Tax-master/city/EditCity";

//propertystatus
import PropertyStatus from "./Components/SuperAdmin/Master/Tax-master/propertystatus/PropertyStatus";
import PropertyStatusAdd from "./Components/SuperAdmin/Master/Tax-master/propertystatus/Add";
import PropertyStatusEdit from "./Components/SuperAdmin/Master/Tax-master/propertystatus/Edit";
//title
import Title from "./Components/SuperAdmin/Master/Tax-master/title/Title";
import TitleAdd from "./Components/SuperAdmin/Master/Tax-master/title/Add";
import TitleEdit from "./Components/SuperAdmin/Master/Tax-master/title/Edit";
//property-type
import PropertyType from "./Components/SuperAdmin/Master/Tax-master/propertytype/PropType";
import PropertyTypeadd from "./Components/SuperAdmin/Master/Tax-master/propertytype/Add";
import PropTypeEdit from "./Components/SuperAdmin/Master/Tax-master/propertytype/Edit";
//Auth Status
import AuthStatus from "./Components/SuperAdmin/Master/Tax-master/authstatus/AuthStatus";
import AuthStatusAdd from "./Components/SuperAdmin/Master/Tax-master/authstatus/Add";
import AuthStatusEdit from "./Components/SuperAdmin/Master/Tax-master/authstatus/Edit";

//Room
import Room from "./Components/SuperAdmin/Master/Tax-master/room/Room";
import RoomAdd from "./Components/SuperAdmin/Master/Tax-master/room/Add";
import RoomEdit from "./Components/SuperAdmin/Master/Tax-master/room/Edit";
import RoomtypeBulk from "./Components/SuperAdmin/Master/Tax-master/room/Bulkupload";

//Company Status
import CompanyStatus from "./Components/SuperAdmin/Master/Tax-master/companystatus/Companystatus";
import CompanyStatusAdd from "./Components/SuperAdmin/Master/Tax-master/companystatus/Add";
import CompanyStatusEdit from "./Components/SuperAdmin/Master/Tax-master/companystatus/Edit";
import CompanyStatusBulk from "./Components/SuperAdmin/Master/Tax-master/companystatus/Bulkupload";

//Occupation
import Occupation from "./Components/SuperAdmin/Master/Tax-master/occupation/Occupation";
import OccupationAdd from "./Components/SuperAdmin/Master/Tax-master/occupation/Add";
import OccupationEdit from "./Components/SuperAdmin/Master/Tax-master/occupation/Edit";
import OccupationtypeBulk from "./Components/SuperAdmin/Master/Tax-master/occupation/Bulkupload";

//Construction
import Construction from "./Components/SuperAdmin/Master/Tax-master/construction/Construction";
import ConstructionAdd from "./Components/SuperAdmin/Master/Tax-master/construction/Add";
import ConstructionEdit from "./Components/SuperAdmin/Master/Tax-master/construction/Edit";
import ConstructiontypeBulk from "./Components/SuperAdmin/Master/Tax-master/construction/Bulkupload";

//route
import RouteLIst from "./Components/SuperAdmin/Master/Tax-master/routename/List";
import RouteAdd from "./Components/SuperAdmin/Master/Tax-master/routename/Add";
import RouteEdit from "./Components/SuperAdmin/Master/Tax-master/routename/Edit";

// propertysubtype
import Usagetypelist from "./Components/SuperAdmin/Master/Tax-master/usagetype/List";
import Usagetypeadd from "./Components/SuperAdmin/Master/Tax-master/usagetype/Add";
import Usagetypeedit from "./Components/SuperAdmin/Master/Tax-master/usagetype/Edit";

//roadfactor
import Roadfactor from "./Components/SuperAdmin/Master/Tax-master/roadfactor/Roadfactor.js";
import RoadfactorAdd from "./Components/SuperAdmin/Master/Tax-master/roadfactor/Add";
import RoadfactorEdit from "./Components/SuperAdmin/Master/Tax-master/roadfactor/Edit";

// usagesubtype
import UsageSubtypelist from "./Components/SuperAdmin/Master/Tax-master/usagesubtype/State";
import UsageSubtypeadd from "./Components/SuperAdmin/Master/Tax-master/usagesubtype/AddState";
import UsageSubtypeedit from "./Components/SuperAdmin/Master/Tax-master/usagesubtype/EditState";

//usagetype
import PropertySubtypelist from "./Components/SuperAdmin/Master/Tax-master/propertysubtype/State";
import PropertySubtypeadd from "./Components/SuperAdmin/Master/Tax-master/propertysubtype/AddState";
import PropertySubtypeedit from "./Components/SuperAdmin/Master/Tax-master/propertysubtype/EditState";
import Usagetypebulk from "./Components/SuperAdmin/Master/Tax-master/usagetype/Bulk";

import Taluka from "./Components/SuperAdmin/Master/Tax-master/taluka/Taluka";
import AddTaluka from "./Components/SuperAdmin/Master/Tax-master/taluka/AddTaluka";
import EditTaluka from "./Components/SuperAdmin/Master/Tax-master/taluka/EditTaluka";

import Ward from "./Components/SuperAdmin/Master/Tax-master/ward/Ward";
import EditWard from "./Components/SuperAdmin/Master/Tax-master/ward/EditWard";
import AddWard from "./Components/SuperAdmin/Master/Tax-master/ward/AddWard";

// import Ward from "./Components/SuperAdmin/Master/Tax-master/ward old/Ward";
// import AddWard from "./Components/SuperAdmin/Master/Tax-master/ward old/AddWard";
// import EditWard from "./Components/SuperAdmin/Master/Tax-master/ward old/EditWard";

import Pincodee from "./Components/SuperAdmin/Master/Tax-master/pincode/Pincode";
import AddPincodee from "./Components/SuperAdmin/Master/Tax-master/pincode/AddPincode";
import EditPincodee from "./Components/SuperAdmin/Master/Tax-master/pincode/EditPincode";

//Ratable values
import Ratable from "./Components/SuperAdmin/Master/ratable-value/Ratable";
import RatableAdd from "./Components/SuperAdmin/Master/ratable-value/Add";
import RatableEdit from "./Components/SuperAdmin/Master/ratable-value/Edit";

// Languages
import Languages from "./Components/SuperAdmin/Master/Tax-master/Languages/Languages";
import LanguagesAdd from "./Components/SuperAdmin/Master/Tax-master/Languages/Add";
import LanguagesEdit from "./Components/SuperAdmin/Master/Tax-master/Languages/Edit";

// Taxes
import Taxes from "./Components/SuperAdmin/Master/Tax-master/Taxes/Languages";
import TaxesAdd from "./Components/SuperAdmin/Master/Tax-master/Taxes/Add";
import TaxesEdit from "./Components/SuperAdmin/Master/Tax-master/Taxes/Edit";

// MainTaxes
import MainTaxes from "./Components/SuperAdmin/Master/Tax-master/MainTaxes/Languages";
import MainTaxesAdd from "./Components/SuperAdmin/Master/Tax-master/MainTaxes/Add";
import MainTaxesEdit from "./Components/SuperAdmin/Master/Tax-master/MainTaxes/Edit";

import ALV from "./Components/SuperAdmin/Master/Tax-master/ALV/ALV.js";
import EditALV from "./Components/SuperAdmin/Master/Tax-master/ALV/Edit.js";
import AddALV from "./Components/SuperAdmin/Master/Tax-master/ALV/Add.js";

//
import Standard_rate from "./Components/SuperAdmin/Master/Tax-master/standard_rate/Standard_rate.js";

import Download_report from "./Components/SuperAdmin/Master/Tax-master/download_report/Standard_rate.js";

import { useContext } from "react";
import MiddelWare from "./Middleware";
import Middleware from "./Middleware";
import { Context } from "./utils/context";

function App() {
  const { usertype } = useContext(Context);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  const [URL, setURL] = useState([
    "/generatedbill",
    "/history",
    "/billhistory",
    "/form",
    "/historyview",
  ]);

  return (
    <>
      {headerText === "/" ? (
        <></>
      ) : URL.includes(headerText) ||
        headerText.includes("/historyview/") ||
        headerText.includes("/history/") ? (
        usertype && usertype === "Data Entry" ? (
          <HeaderDataEntry />
        ) : (
          <></>
        )
      ) : (usertype && usertype === "Data Entry Head") ||
        usertype === "Super Admin" ? (
        <HeaderSuperAdmin />
      ) : (
        <></>
      )}

      <Routes>
        <Route
          path="/"
          element={<MiddelWare component={Login} />}
          // element={<Login />}
        />
        {/* Data Entry */}
        {usertype && usertype === "Data Entry" ? (
          <>
            <Route
              path="/generatedbill"
              element={<MiddelWare component={GeneratedBill} />}
              //  element={<GeneratedBill />}
            />
            <Route
              path="/history/:id"
              element={<MiddelWare component={BillView} />}
              //  element={<BillView />}
            />
            <Route
              path="/billhistory"
              element={<MiddelWare component={BillHistory} />}
              //  element={<BillHistory />}
            />
            <Route
              path="/form"
              element={<MiddelWare component={Form} />}
              //  element={<Form />}
            />
            <Route
              path="/historyview/:id"
              element={<MiddelWare component={HistoryView} />}
              //  element={<HistoryView />}
            />
          </>
        ) : (
          <></>
        )}

        {/* Add other non-protected routes here */}

        {/* SuperAdmin */}
        {/* Tax Management Started */}

        {usertype &&
        (usertype === "Data Entry Head" || usertype === "Super Admin") ? (
          <>
            {/* Reports */}
            <Route
              path="/data-entry"
              element={<MiddelWare component={Dataentry} />}
              // element={<Dataentry />}
            />

            <Route
              path="/data-entry-approved"
              element={<MiddelWare component={DataentryApproved} />}
              // element={<Dataentry />}
            />

            <Route
              path="/data-entry-rejected"
              element={<MiddelWare component={DataentryRejected} />}
              // element={<Dataentry />}
            />

            <Route
              path="/data-entry-view/:id"
              element={<MiddelWare component={DataentryView} />}
              // element={<Dataentry />}
            />

            <Route
              path="/Ward-Wise"
              element={<MiddelWare component={WardWise} />}
              // element={<WardWise />}
            />

            <Route
              path="/assesment"
              element={<MiddelWare component={Assesment} />}
              // element={<Assesment />}
            />
            <Route
              path="/deletion"
              element={<MiddelWare component={Deletion} />}
              // element={<Deletion />}
            />
            {/* Reports */}
          </>
        ) : (
          <></>
        )}

        {usertype && usertype === "Super Admin" ? (
          <>
            <Route
              path="/dashboard"
              element={<MiddelWare component={DashBoard} />}
            />
            {/* Languages  start */}
            <Route
              path="/tax-master/languages"
              element={<MiddelWare component={Languages} />}
              //  element={<Languages />}
            />
            <Route
              path="/tax-master/languagesadd"
              element={<MiddelWare component={LanguagesAdd} />}
              //  element={<LanguagesAdd />}
            />
            <Route
              path="/tax-master/Languagesedit/:id"
              element={<MiddelWare component={LanguagesEdit} />}
              // element={<LanguagesEdit />}
            />
            {/* <Route
              path="/tax-master/languages"
              element={<MiddelWare component={Languages} />}
              //  element={<LanguagesList />}
            />
            <Route
              path="/tax-master/add"
              element={<MiddelWare component={LanguagesAdd} />}
              // element={<LanguagesAdd />}
            />
            <Route
              path="/tax-master/edit"
              element={<MiddelWare component={LanguagesEdit} />}
              //  element={<LanguagesEdit />}
            /> */}
            {/* Languages end */}

            {/*Taxes  */}

            <Route
              path="/tax-master/taxes"
              element={<MiddelWare component={Taxes} />}
            />
            <Route
              path="/tax-master/taxesadd"
              element={<MiddelWare component={TaxesAdd} />}
            />
            <Route
              path="/tax-master/taxesedit/:id"
              element={<MiddelWare component={TaxesEdit} />}
            />

            {/*Taxes  */}

            <Route
              path="/tax-master/main-taxes"
              element={<MiddelWare component={MainTaxes} />}
            />
            <Route
              path="/tax-master/main-taxesadd"
              element={<MiddelWare component={MainTaxesAdd} />}
            />
            <Route
              path="/tax-master/main-taxesedit/:id"
              element={<MiddelWare component={MainTaxesEdit} />}
            />
            {/*  */}
            <Route
              path="/tax-master"
              element={<MiddelWare component={TaxMasterList} />}
              //  element={<TaxMasterList />}
            />
            <Route
              path="/tax-master/add"
              element={<MiddelWare component={TaxmasterAdd} />}
              // element={<TaxmasterAdd />}
            />
            <Route
              path="/tax-master/edit"
              element={<MiddelWare component={TaxmasterEdit} />}
              //  element={<TaxmasterEdit />}
            />
            {/* propertyStatus */}
            <Route
              path="/tax-master/propertystatus"
              element={<MiddelWare component={PropertyStatus} />}

              // element={<PropertyStatus />}
            />
            <Route
              path="/tax-master/propertystatusadd"
              element={<MiddelWare component={PropertyStatusAdd} />}
              // element={<PropertyStatusAdd />}
            />
            <Route
              path="/tax-master/editpropertystatus/:id"
              element={<MiddelWare component={PropertyStatusEdit} />}
              // element={<PropertyStatusEdit />}
            />
            {/* Title */}
            <Route
              path="/tax-master/title"
              element={<MiddelWare component={Title} />}
              //  element={<Title />}
            />
            <Route
              path="/tax-master/titleadd"
              element={<MiddelWare component={TitleAdd} />}
              //  element={<TitleAdd />}
            />
            <Route
              path="/tax-master/edittitle/:id"
              element={<MiddelWare component={TitleEdit} />}
              //  element={<TitleEdit />}
            />
            {/* Property Type */}
            <Route
              path="/tax-master/propertytype"
              element={<MiddelWare component={PropertyType} />}
              //  element={<PropertyType />}
            />
            <Route
              path="/tax-master/propertytypeadd"
              element={<MiddelWare component={PropertyTypeadd} />}
              // element={<PropertyTypeadd />}
            />
            <Route
              path="/tax-master/editpropertytype/:id"
              element={<MiddelWare component={PropTypeEdit} />}
              // element={<PropTypeEdit />}
            />

            {/* Property Type */}
            <Route
              path="/tax-master/roadfactor"
              element={<MiddelWare component={Roadfactor} />}
            />
            <Route
              path="/tax-master/roadfactoradd"
              element={<MiddelWare component={RoadfactorAdd} />}
            />
            <Route
              path="/tax-master/editroadfactor/:id"
              element={<MiddelWare component={RoadfactorEdit} />}
            />

            {/* Auth Status */}
            <Route
              path="/tax-master/authstatus"
              element={<MiddelWare component={AuthStatus} />}
              //  element={<AuthStatus />}
            />
            <Route
              path="/tax-master/authstatusadd"
              element={<MiddelWare component={AuthStatusAdd} />}
              //  element={<AuthStatusAdd />}
            />
            <Route
              path="/tax-master/editauthstatus/:id"
              element={<MiddelWare component={AuthStatusEdit} />}
              // element={<AuthStatusEdit />}
            />

            {/* Floor  */}
            <Route
              path="/tax-master/floor"
              element={<MiddelWare component={Floor} />}
              // element={<Floor />}
            />
            <Route
              path="/tax-master/flooradd"
              element={<MiddelWare component={FloorAdd} />}
              // element={<FloorAdd />}
            />
            <Route
              path="/tax-master/flooredit/:id"
              element={<MiddelWare component={FloorEdit} />}
              // element={<FloorEdit />}
            />
            {/* Billing Structure  */}
            <Route
              path="/tax-master/billingstructure"
              element={<MiddelWare component={Billingstructure} />}
              // element={<Billingstructure />}
            />
            <Route
              path="/tax-master/billingstructureadd"
              element={<MiddelWare component={BillingstructureAdd} />}
              // element={<BillingstructureAdd />}
            />
            <Route
              path="/tax-master/billingstructureedit/:id"
              element={<MiddelWare component={BillingstructureEdit} />}
              // element={<BillingstructureEdit />}
            />
            {/* Land Type  */}
            <Route
              path="/tax-master/landtype"
              element={<MiddelWare component={Landtype} />}
              //  element={<Landtype />}
            />
            <Route
              path="/tax-master/landtypeadd"
              element={<MiddelWare component={LandtypeAdd} />}
              //  element={<LandtypeAdd />}
            />
            <Route
              path="/tax-master/landtypeedit/:id"
              element={<MiddelWare component={LandtypeEdit} />}
              // element={<LandtypeEdit />}
            />
            {/* Land Use Type  */}
            <Route
              path="/tax-master/landusetype"
              element={<MiddelWare component={Landusetype} />}
            />
            <Route
              path="/tax-master/landusetypeadd"
              element={<MiddelWare component={LandusetypeAdd} />}
            />
            <Route
              path="/tax-master/landusetypeedit/:id"
              element={<MiddelWare component={LandusetypeEdit} />}
            />
            {/* Agency Name */}
            <Route
              path="/tax-master/agencyname"
              element={<MiddelWare component={Agencyname} />}
              //  element={<Agencyname />}
            />
            <Route
              path="/tax-master/agencynameadd"
              element={<MiddelWare component={AgencynameAdd} />}
              //  element={<AgencynameAdd />}
            />
            <Route
              path="/tax-master/agencynameedit/:id"
              element={<MiddelWare component={AgencynameEdit} />}
              // element={<AgencynameEdit />}
            />
            {/* Licence Type */}
            <Route
              path="/tax-master/licencetype"
              element={<MiddelWare component={Licencetype} />}
              //  element={<Licencetype />}
            />
            <Route
              path="/tax-master/licencetypeadd"
              element={<MiddelWare component={LicencetypeAdd} />}
              // element={<LicencetypeAdd />}
            />
            <Route
              path="/tax-master/licencetypeedit/:id"
              element={<MiddelWare component={LicencetypeEdit} />}
              // element={<LicencetypeEdit />}
            />
            <Route
              path="/tax-master/licencetypebulk"
              element={<LicencetypeBulk />}
            />
            {/* Room */}
            <Route
              path="/tax-master/room"
              element={<MiddelWare component={Room} />}
              //  element={<Room />}
            />
            <Route
              path="/tax-master/roomadd"
              element={<MiddelWare component={RoomAdd} />}
              //  element={<RoomAdd />}
            />
            <Route
              path="/tax-master/roomedit/:id"
              element={<MiddelWare component={RoomEdit} />}
              //  element={<RoomEdit />}
            />
            <Route
              path="/tax-master/roomtypebulk"
              element={<MiddelWare component={RoomtypeBulk} />}
            />
            {/* CompanyStatus */}
            <Route
              path="/tax-master/companystatus"
              element={<MiddelWare component={CompanyStatus} />}
              //  element={<CompanyStatus />}
            />
            <Route
              path="/tax-master/companystatusadd"
              element={<MiddelWare component={CompanyStatusAdd} />}
              // element={<CompanyStatusAdd />}
            />
            <Route
              path="/tax-master/companystatusedit/:id"
              element={<MiddelWare component={CompanyStatusEdit} />}
              // element={<CompanyStatusEdit />}
            />
            <Route
              path="/tax-master/companystatusbulk"
              element={<MiddelWare component={CompanyStatusBulk} />}
            />
            {/* Occupation  */}
            <Route
              path="/tax-master/occupation"
              element={<MiddelWare component={Occupation} />}
              //  element={<Occupation />}
            />
            <Route
              path="/tax-master/occupationadd"
              element={<MiddelWare component={OccupationAdd} />}
              //  element={<OccupationAdd />}
            />
            <Route
              path="/tax-master/occupationedit/:id"
              element={<MiddelWare component={OccupationEdit} />}
              // element={<OccupationEdit />}
            />
            <Route
              path="/tax-master/occupationbulk"
              element={<MiddelWare component={OccupationtypeBulk} />}
            />
            {/* Construction  */}
            <Route
              path="/tax-master/construction"
              element={<MiddelWare component={Construction} />}
              //  element={<Construction />}
            />
            <Route
              path="/tax-master/constructionadd"
              element={<MiddelWare component={ConstructionAdd} />}
              // element={<ConstructionAdd />}
            />
            <Route
              path="/tax-master/constructionedit/:id"
              element={<MiddelWare component={ConstructionEdit} />}
            />
            <Route
              path="/tax-master/constructionebulk"
              element={<MiddelWare component={ConstructiontypeBulk} />}
            />
            <Route
              path="/tax"
              element={<MiddelWare component={TaxList} />}
              //  element={<TaxList />}
            />
            <Route
              path="/tax/add"
              element={<MiddelWare component={TaxAdd} />}
              //  element={<TaxAdd />}
            />
            <Route
              path="/tax/edit"
              element={<MiddelWare component={TaxEdit} />}
              // element={<TaxEdit />}
            />
            <Route
              path="/sub-tax"
              element={<MiddelWare component={Subtaxlist} />}
              // element={<Subtaxlist />}
            />
            <Route
              path="/customer-list"
              element={<MiddelWare component={CustomerList} />}
              // element={<CustomerList />}
            />
            <Route
              path="/department"
              element={<MiddelWare component={DepartmentList} />}
              // element={<DepartmentList />}
            />
            <Route
              path="/document-master"
              element={<MiddelWare component={Documentmasterlist} />}
              // element={<Documentmasterlist />}
            />
            <Route
              path="/fine-master"
              element={<MiddelWare component={Finemasterlist} />}
              // element={<Finemasterlist />}
            />
            <Route
              path="/notice-master"
              element={<MiddelWare component={Noticemasterlist} />}
              // element={<Noticemasterlist />}
            />
            <Route
              path="/payment-reminder"
              element={<MiddelWare component={Paymentremindermasterlist} />}
              // element={<Paymentremindermasterlist />}
            />
            {/* role management */}
            <Route
              path="/rolemanagement"
              element={<MiddelWare component={Rolemanagement} />}
              // element={<Rolemanagement />}
            />
            <Route
              path="/rolemanagement/addrole"
              element={<MiddelWare component={RolemanagementAdd} />}
              // element={<RolemanagementAdd />}
            />
            <Route
              path="/rolemanagement/editrole/:id"
              element={<MiddelWare component={RolemanagementEdit} />}
              // element={<RolemanagementEdit />}
            />
            {/* Users management */}
            <Route
              path="/usermanagement"
              element={<MiddelWare component={Usersmanagement} />}
              // element={<Usersmanagement />}
            />
            <Route
              path="/usermanagement/adduser"
              element={<MiddelWare component={UsersmanagementAdd} />}
              // element={<UsersmanagementAdd />}
            />
            <Route
              path="/usermanagement/edituser/:id"
              element={<MiddelWare component={UsersmanagementEdit} />}
              // element={<UsersmanagementEdit />}
            />
            <Route
              path="/sub-tax-master"
              element={<MiddelWare component={Subtaxmasterlist} />}
              // element={<Subtaxmasterlist />}
            />
            <Route
              path="/customer-view"
              element={<MiddelWare component={View} />}
              // element={<View />}
            />
            <Route
              path="/Upload-document"
              element={<MiddelWare component={Upload} />}
              // element={<Upload />}
            />
            <Route
              path="/counterwise"
              element={<MiddelWare component={Counterwise} />}
              // element={<Counterwise />}
            />
            <Route
              path="/company"
              element={<MiddelWare component={Company} />}
              // element={<Company />}
            />
            <Route
              path="/user-details"
              element={<MiddelWare component={UserDetails} />}
              // element={<UserDetails />}
            />
            <Route
              path="/online"
              element={<MiddelWare component={Online} />}
              // element={<Online />}
            />
            <Route
              path="/tax-rates"
              element={<MiddelWare component={TaxRates} />}
              // element={<TaxRates />}
            />
            <Route
              path="/product"
              element={<MiddelWare component={Product} />}
              // element={<Product />}
            />
            <Route
              path="/notification"
              element={<MiddelWare component={Notification} />}
              // element={<Notification />}
            />
            <Route
              path="/rolemanagement"
              element={<MiddelWare component={Rolemanagement} />}
              // element={<Rolemanagement />}
            />
            {/* country Routes */}
            <Route
              path="/tax-master/country"
              element={<MiddelWare component={Country} />}
              // element={<Country />}
            />
            <Route
              path="/tax-master/country/add"
              element={<MiddelWare component={AddCountry} />}
              // element={<AddCountry />}
            />
            <Route
              path="/tax-master/country/edit/:id"
              element={<MiddelWare component={EditCountry} />}
              // element={<EditCountry />}
            ></Route>
            {/* state Routes */}
            <Route
              path="/tax-master/state"
              element={<MiddelWare component={State} />}
              // element={<State />}
            ></Route>
            <Route
              path="/tax-master/state/add"
              element={<MiddelWare component={AddState} />}
              // element={<AddState />}
            ></Route>
            <Route
              path="/tax-master/state/edit/:id"
              element={<MiddelWare component={EditState} />}
              // element={<EditState />}
            ></Route>
            {/* District Routes  */}
            <Route
              path="/tax-master/district"
              element={<MiddelWare component={District} />}
            ></Route>
            <Route
              path="/tax-master/district/add"
              element={<MiddelWare component={AddDistrict} />}
            ></Route>
            <Route
              path="/tax-master/district/edit/:id"
              element={<MiddelWare component={EditDistrict} />}
            ></Route>
            {/* Zone Routes   */}
            <Route
              path="/tax-master/zone"
              element={<MiddelWare component={Zone} />}
              // element={<District />}
            ></Route>
            <Route
              path="/tax-master/zone/add"
              element={<MiddelWare component={AddZone} />}
              // element={<AddDistrict />}
            ></Route>
            <Route
              path="/tax-master/zone/edit/:id"
              element={<MiddelWare component={EditZone} />}
              // element={<EditDistrict />}
            ></Route>
            {/* City Routes  */}
            <Route
              path="/tax-master/city"
              element={<MiddelWare component={City} />}
              // element={<City />}
            ></Route>
            <Route
              path="/tax-master/city/add"
              element={<MiddelWare component={AddCity} />}
              // element={<AddCity />}
            ></Route>
            <Route
              path="/tax-master/city/edit/:id"
              element={<MiddelWare component={EditCity} />}
            ></Route>
            {/* Taluka */}
            <Route
              path="/tax-master/taluka"
              element={<Middleware component={Taluka} />}
            ></Route>
            <Route
              path="/tax-master/taluka/add"
              element={<Middleware component={AddTaluka} />}
            ></Route>
            <Route
              path="/tax-master/taluka/edit/:id"
              element={<Middleware component={EditTaluka} />}
            ></Route>
            {/* Block  */}
            {/* <Route
              path="/tax-master/block"
              element={<MiddelWare component={Block} />}
            ></Route>
            <Route
              path="/tax-master/block/add"
              element={<MiddelWare component={AddBlock} />}
            ></Route>
            <Route
              path="/tax-master/block/edit/:id"
              element={<MiddelWare component={EditBlock} />}
            ></Route> */}
            {/* Ward   */}
            <Route
              path="/tax-master/ward"
              element={<MiddelWare component={Ward} />}
            ></Route>
            <Route
              path="/tax-master/ward/add"
              element={<MiddelWare component={AddWard} />}
            ></Route>
            <Route
              path="/tax-master/ward/edit/:id"
              element={<Middleware component={EditWard} />}
            ></Route>
            {/* pincode   */}
            <Route
              path="/tax-master/pincode"
              element={<MiddelWare component={Pincodee} />}
            ></Route>
            <Route
              path="/tax-master/pincode/add"
              element={<MiddelWare component={AddPincodee} />}
            ></Route>
            <Route
              path="/tax-master/pincode/edit/:id"
              element={<Middleware component={EditPincodee} />}
            ></Route>
            <Route
              path="/govt"
              element={<MiddelWare component={Govt} />}
              // element={<Govt />}
            />
            <Route
              path="/localization"
              element={<MiddelWare component={Localization} />}
              // element={<Localization />}
            />
            <Route
              path="/export"
              element={<MiddelWare component={Export} />}
              // element={<Export />}
            />
            <Route
              path="/account-manager"
              element={<MiddelWare component={AccountManager} />}
              // element={<AccountManager />}
            />
            {/* {title-Route} */}
            {/* nikhil */}
            <Route
              path="/customer-master"
              element={<MiddelWare component={CustomerMasterList} />}
              // element={<CustomerMasterList />}
            />
            <Route
              path="/customer-master/add"
              element={<MiddelWare component={CustomerMasterAdd} />}
              // element={<CustomerMasterAdd />}
            />
            <Route
              path="/customer-master/edit/:id"
              element={<MiddelWare component={CustomerMasterEdit} />}
              // element={<CustomerMasterEdit />}
            />
            {/* Route Structure  */}
            <Route
              path="/tax-master/routelist"
              element={<MiddelWare component={RouteLIst} />}
              // element={<RouteLIst />}
            />
            <Route
              path="/tax-master/routeadd"
              element={<MiddelWare component={RouteAdd} />}
              // element={<RouteAdd />}
            />
            <Route
              path="/tax-master/routeedit/:id"
              element={<MiddelWare component={RouteEdit} />}
              // element={<RouteEdit />}
            />
            {/* Usagetype Structure  */}
            <Route
              path="/tax-master/usagetypelist"
              element={<MiddelWare component={Usagetypelist} />}
              // element={<Usagetypelist />}
            />
            <Route
              path="/tax-master/usagetypeadd"
              element={<MiddelWare component={Usagetypeadd} />}
              // element={<Usagetypeadd />}
            />
            <Route
              path="/tax-master/usagetypeedit/:id"
              element={<MiddelWare component={Usagetypeedit} />}
              // element={<Usagetypeedit />}
            />
            <Route
              path="/tax-master/usagetypebulk"
              element={<MiddelWare component={Usagetypebulk} />}
              // element={<Usagetypebulk />}
            />
            {/* PropertySubtype Structure  */}
            <Route
              path="/tax-master/propertysubtypelist"
              element={<MiddelWare component={PropertySubtypelist} />}
              // element={<PropertySubtypelist />}
            />
            <Route
              path="/tax-master/propertysubtypeadd"
              element={<MiddelWare component={PropertySubtypeadd} />}
              // element={<PropertySubtypeadd />}
            />
            <Route
              path="/tax-master/propertysubtypeedit/:id"
              element={<MiddelWare component={PropertySubtypeedit} />}
              // element={<PropertySubtypeedit />}
            />
            {/* UsageSubtype Structure  */}
            <Route
              path="/tax-master/usagesubtypelist"
              element={<MiddelWare component={UsageSubtypelist} />}
              // element={<PropertySubtypelist />}
            />
            <Route
              path="/tax-master/usagesubtypeadd"
              element={<MiddelWare component={UsageSubtypeadd} />}
              // element={<PropertySubtypeadd />}
            />
            <Route
              path="/tax-master/usagesubtypeedit/:id"
              element={<MiddelWare component={UsageSubtypeedit} />}
              // element={<PropertySubtypeedit />}
            />
            <Route
              path="/tax-master/alv"
              element={<MiddelWare component={ALV} />}
              // element={<PropertySubtypelist />}
            />
            <Route
              path="/tax-master/alvadd"
              element={<MiddelWare component={AddALV} />}
              // element={<PropertySubtypeadd />}
            />
            {/* <Route
              path="/tax-master/alvedit/:id"
              element={<MiddelWare component={UsageSubtypeedit} />}
              // element={<PropertySubtypeedit />} 
            />*/}
            <Route
              path="/tax-master/standard_rate/:id"
              element={<MiddelWare component={Standard_rate} />}
            />

            <Route
              path="/tax-master/download-report"
              element={<MiddelWare component={Download_report} />}
            />
            {/* Ratable Values   */}
            <Route
              path="/master/ratablevalueslist"
              element={<MiddelWare component={Ratable} />}
              // element={<PropertySubtypelist />}
            />
            <Route
              path="/master/ratablevaluesadd"
              element={<MiddelWare component={RatableAdd} />}
              // element={<PropertySubtypeadd />}
            />
            <Route
              path="/master/ratablevaluesedit/:id"
              element={<MiddelWare component={RatableEdit} />}
              // element={<PropertySubtypeedit />}
            />
          </>
        ) : (
          <></>
        )}

        <Route path="*" component={PageNotFound} />
      </Routes>
    </>
  );
}

export default App;
