import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import circle from "../../assets/icons/circle.png";
import Sidebar from "../layout/Sidebar";
import Select from "react-select";
import { useForm, useFieldArray, Controller } from "react-hook-form";

// import "./table.css";
// import "../Tax.css";

import { Context } from "../../../../utils/context";

const Add = () => {
  const { getData, postData, editData } = useContext(Context);
  const [usagetype, setUsageType] = useState();
  const [usagesubtype, setUsageSubType] = useState();
  const [taxtype, setTaxType] = useState();
  const [taxes, settaxes] = useState([]);
  const [maintaxes, setmaintaxes] = useState([]);
  const [city, setCity] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();

  // const [fields, setFields] = useState([
  // {
  //   ratable_value_start: "",
  //   ratable_value_end: "",
  //   property_tax: "",
  //   residential: "",
  //   non_residential: "",
  //   employment_tax_on_nonresidential_property: "",
  //   tax_type_id: "",
  //   fire_tax: "",
  //   tree_tax: "",
  //   usage_type_id: "",
  //   usage_subtype_id: "",
  // },
  // ]);
  // const [city, setCity] = useState([]);

  // const addNewField = async () => {
  //   let tax = await taxes?.map((tax) => ({
  //     id: tax.id,
  //     name: tax.name,
  //     no: 0,
  //   }));

  //   await setFields([
  //     ...fields,
  //     {
  //       ratable_value_start: "",
  //       ratable_value_end: "",
  //       property_tax: "",
  //       residential: "",
  //       non_residential: "",
  //       employment_tax_on_nonresidential_property: "",
  //       tax_type_id: "",
  //       taxes: tax,
  //       fire_tax: "",
  //       tree_tax: "",
  //       usage_type_id: "",
  //       usage_subtype_id: "",
  //     },
  //   ]);
  // };

  // const removeField = (index) => {
  //   const updatedFields = [...fields];
  //   updatedFields.splice(index, 1);
  //   setFields(updatedFields);
  // };

  // const getDataAll = async () => {
  //   {
  //     const res = await getData("/api/getcity");
  //     const option = [];

  //     if (res.success) {
  //       res?.data?.map((data) => {
  //         option.push({
  //           value: data.city_id,
  //           name: "city_id",
  //           label: data.name,
  //         });
  //       });
  //     }
  //     await setCity(option);
  //   }
  //   {
  //     const res = await getData("/api/get-taxes");
  //     settaxes(res.data.data);
  //   }
  // };

  // useEffect(() => {
  //   getDataAll();
  // }, []);

  // const handleClicks = async (name, index = null) => {
  //   switch (name) {
  //     case "city_id": {
  //       const res = await getData("/api/getcity");
  //       const option = [];

  //       if (res.success) {
  //         res?.data?.map((data) => {
  //           option.push({
  //             value: data.city_id,
  //             name: "city_id",
  //             label: data.name,
  //           });
  //         });
  //       }
  //       await setCity(option);
  //       await setFormData1({
  //         ...formData1,
  //         city_id: "",
  //       });
  //       break;
  //     }

  //     case "usage_type_id": {
  //       const res = await getData("/api/getusagetype");
  //       const option = [];

  //       if (res.success) {
  //         res?.data?.map((data) => {
  //           option.push({
  //             value: data.usagetype_id,
  //             name: `usage_type_id`,
  //             label: data.name,
  //           });
  //         });
  //       }

  //       await setUsageType(option);
  //       const updatedFields = [...fields];
  //       updatedFields[index].usage_type_id = "";
  //       updatedFields[index].usage_subtype_id = "";
  //       setFields(updatedFields);
  //       break;
  //     }

  //     case "usage_subtype_id": {
  //       const res = await editData("/api/getusagesubtype", {
  //         id: index,
  //       });

  //       const option = [];
  //       if (res.success) {
  //         res?.data?.map((data) => {
  //           option.push({
  //             value: data.usagesubtype_id,
  //             name: "usage_subtype_id",
  //             label: data.name,
  //           });
  //         });
  //       }
  //       await setUsageSubType(option);
  //       break;
  //     }

  //     case "tax_type_id": {
  //       const res = await getData("/api/gettaxtype");
  //       const option = [];

  //       if (res.success) {
  //         res?.data?.data?.map((data) => {
  //           option.push({
  //             value: data.id,
  //             name: `tax_type_id`,
  //             label: data.name,
  //           });
  //         });
  //       }

  //       await setTaxType(option);
  //       const updatedFields = [...fields];
  //       updatedFields[index].tax_type_id = "";
  //       setFields(updatedFields);
  //       break;
  //     }

  //     default:
  //       break;
  //   }
  // };

  // const [formData1, setFormData1] = useState({
  //   city_id: "",
  //   fields: fields,
  // });

  // const handleChange1 = (e) => {
  //   if (e?.name == "city_id") {
  //     setFormData1({ ...formData1, [e.name]: e });
  //   } else {
  //     setFormData1({ ...formData1, [e.target.name]: e.target.value });
  //   }
  // };

  // const [errors, setErrors] = useState({});

  // const validateForm = () => {
  //   let errors = {};

  //   // Somethingg

  //   const regexNumber = /^\d/;
  //   const regexContact = /^\d{10}$/;
  //   const regexAdhar = /^\d{12}$/;
  //   const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;

  //   const regexNumricAndFloat = /^[0-9]+(\.[0-9]+)?(\s[0-9]+(\.[0-9]+)?)*$/;

  //   const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

  //   if (!formData1.city_id) {
  //     errors.city_id = "Please Select City";
  //   }

  //   fields.forEach((field, index) => {
  //     if (!field.ratable_value_start) {
  //       errors[`ratable_value_start${index}`] =
  //         "Ratable Value Start is required";
  //     } else if (!regexAlfaNumric.test(field.ratable_value_start.trim())) {
  //       errors[`ratable_value_start${index}`] =
  //         "Enter valid Ratable Value Start and accepts only alphanumeric values ";
  //     }

  //     if (!field.ratable_value_end) {
  //       errors[`ratable_value_end${index}`] = "Ratable Value End is required";
  //     } else if (!regexAlfaNumric.test(field.ratable_value_end.trim())) {
  //       errors[`ratable_value_end${index}`] =
  //         "Enter valid Ratable Value End and accepts only alphanumeric values ";
  //     }

  //     // if (!field.property_tax) {
  //     //   errors[`property_tax${index}`] = "Property Tax is required";
  //     // } else if (!regexNumricAndFloat.test(field.property_tax.trim())) {
  //     //   errors[`property_tax${index}`] =
  //     //     "Enter valid Property Tax and accepts only alphanumeric values ";
  //     // }

  //     // if (!field.residential) {
  //     //   errors[`residential${index}`] = "residential is required";
  //     // } else if (!regexNumricAndFloat.test(field.residential.trim())) {
  //     //   errors[`residential${index}`] =
  //     //     "Enter valid residential and accepts only alphanumeric values ";
  //     // }

  //     // if (!field.non_residential) {
  //     //   errors[`non_residential${index}`] = "non residential is required";
  //     // } else if (!regexNumricAndFloat.test(field.non_residential.trim())) {
  //     //   errors[`non_residential${index}`] =
  //     //     "Enter valid non residential and accepts only alphanumeric values ";
  //     // }

  //     // if (!field.employment_tax_on_nonresidential_property) {
  //     //   errors[`employment_tax_on_nonresidential_property${index}`] =
  //     //     "employment_tax_on_nonresidential_property is required";
  //     // } else if (
  //     //   !regexNumricAndFloat.test(
  //     //     field.employment_tax_on_nonresidential_property.trim()
  //     //   )
  //     // ) {
  //     //   errors[`employment_tax_on_nonresidential_property${index}`] =
  //     //     "Enter valid employment_tax_on_nonresidential_property and accepts only alphanumeric values ";
  //     // }

  //     // if (!field.tax_type_id) {
  //     //   errors[`tax_type_id${index}`] = "tax type is required";
  //     // }

  //     // else if (!regexNumricAndFloat.test(field.tax_type_id.trim())) {
  //     //   errors[`tax_type_id${index}`] =
  //     //     "Enter valid tax type and accepts only alphanumeric values ";
  //     // }

  //     // if (!field.fire_tax) {
  //     //   errors[`fire_tax${index}`] = "fire_tax is required";
  //     // } else if (!regexNumricAndFloat.test(field.fire_tax.trim())) {
  //     //   errors[`fire_tax${index}`] =
  //     //     "Enter valid fire_tax and accepts only alphanumeric values ";
  //     // }

  //     // if (!field.tree_tax) {
  //     //   errors[`tree_tax${index}`] = "tree_tax is required";
  //     // } else if (!regexNumricAndFloat.test(field.tree_tax.trim())) {
  //     //   errors[`tree_tax${index}`] =
  //     //     "Enter valid tree_tax and accepts only alphanumeric values ";
  //     // }

  //     field?.taxes.map((tax, Tindex) => {
  //       if (!tax.no) {
  //         errors[`tax${index}${Tindex}`] = "tax is required";
  //       } else if (!regexNumricAndFloat.test(tax.no)) {
  //         errors[`tax${index}${Tindex}`] =
  //           "Enter valid tax and accepts only alphanumeric values ";
  //       }
  //     });
  //   });

  //   return errors;
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const validationErrors1 = validateForm();

  //   if (Object.keys(validationErrors1).length > 0) {
  //     setErrors(validationErrors1);
  //   } else {
  //     try {
  //       formData1.fields = fields;

  //       const response = await postData("/master/postratablevalues", formData1);
  //       if (response.success) {
  //         setShowModal(true);
  //         setTimeout(() => {
  //           setShowModal(false);
  //           navigate("/master/ratablevalueslist");
  //         }, 1000);
  //       } else {
  //         setShowErrorModal(true);
  //         setTimeout(() => {
  //           setShowErrorModal(false);
  //           navigate(`/master/ratablevalueslist`);
  //         }, 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //     }
  //   }
  // };

  const errorStyle = {
    // border: "1px solid red",
    color: "red",
    marginLeft: "5px",
    fontSize: "12px",
  };

  const errorBoarderStyle = {
    border: "1px solid red",
  };

  const relatable = {
    whiteSpace: "pre-wrap",
    width: "15%",
  };

  const getDataAll = async () => {
    {
      const res = await getData("/api/getcity");
      const option = [];

      if (res.success) {
        res?.data?.map((data) => {
          option.push({
            value: data.city_id,
            name: "city_id",
            label: data.name,
          });
        });
      }
      await setCity(option);
    }
    {
      const res = await getData("/api/get-taxes");
      settaxes(res.data.data);
    }

    {
      const res = await getData("/api/get-main-taxes");
      setmaintaxes(res.data.data);
    }

    {
      const res = await getData("/api/getusagetype");
      const option = [];
      if (res.success) {
        res?.data?.map((data) => {
          option.push({
            value: data.usagetype_id,
            name: `usage_type_id`,
            label: data.name,
          });
        });
      }
      await setUsageType(option);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const GetAllUsageSubType = async (data) => {
    const res = await editData("/api/getusagesubtype", {
      id: data,
    });

    const option = [];
    if (res.success) {
      res?.data?.map((data) => {
        option.push({
          value: data.usagesubtype_id,
          name: "usage_subtype_id",
          label: data.name,
        });
      });
    }
    setUsageSubType(option);
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      city_id: "",
      fields: [
        // {
        // ratable_value_start: "",
        // ratable_value_end: "",
        // taxes: taxes?.map(() => ({ no: "" })),
        // usage_type_id: null,
        // usage_subtype_id: null,
        // },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const response = await postData("/master/postratablevalues", data);
      if (response.success) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate("/master/ratablevalueslist");
        }, 1000);
      } else {
        setShowErrorModal(true);
        setTimeout(() => {
          setShowErrorModal(false);
          navigate(`/master/ratablevalueslist`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const addNewField = () => {
    append({
      ratable_value_start: "",
      ratable_value_end: "",
      taxes: taxes?.map((tax) => ({ id: tax?.id, no: "" })),
      maintaxes: maintaxes?.map((tax) => ({ id: tax?.id, no: "" })),
      usage_type_id: null,
      usage_subtype_id: null,
    });
  };

  const removeField = (index) => {
    remove(index);
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/master/ratablevalueslist">
                          <button type="button" className="btn btn-back">
                            Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <div className="row">
                        <div className="col">
                          <div className="main-form-section mt-5 ">
                            <div className="row justify-content-center ">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-3 col-form-label"
                              >
                                Select City{" "}
                              </label>
                              <div className="col-sm-6">
                                <Controller
                                  name="city_id"
                                  {...register(`city_id`, {
                                    required: "Please Select City",
                                  })}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      inputStyle={{
                                        borderColor: errors.city_id
                                          ? "red"
                                          : getValues("city_id")
                                          ? "green"
                                          : "",
                                      }}
                                      {...field}
                                      options={city}
                                      onChange={(selectedOption) => {
                                        field.onChange(selectedOption);
                                      }}
                                    />
                                  )}
                                />

                                {errors.city_id && (
                                  <span style={errorStyle}>
                                    {errors.city_id.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <table className="table table-bordered align-items-center table-sm mt-5">
                                <thead className="thead-light">
                                  <tr>
                                    <th>No</th>
                                    <th>Taxes</th>
                                    {maintaxes?.length &&
                                      maintaxes?.map((tax, index) => (
                                        <th key={index}>{tax?.name}</th>
                                      ))}
                                    {taxes?.length &&
                                      taxes?.map((tax, index) => (
                                        <th key={index}>{tax?.name}</th>
                                      ))}
                                    <th>Usage Type</th>
                                    <th>Usage SubType</th>
                                    <th>Remove</th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th>Ratable Value</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields.map((field, index) => (
                                    <tr key={field.id}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <input
                                          type="number"
                                          {...register(
                                            `fields.${index}.ratable_value_start`,
                                            { required: true }
                                          )}
                                          className={`form-control ${
                                            errors?.fields?.[index]
                                              ?.ratable_value_start
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                        To
                                        <input
                                          type="number"
                                          {...register(
                                            `fields.${index}.ratable_value_end`,
                                            { required: true }
                                          )}
                                          className={`form-control ${
                                            errors?.fields?.[index]
                                              ?.ratable_value_end
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                        />
                                      </td>
                                      {field.maintaxes.map((tax, taxIndex) => (
                                        <td key={taxIndex}>
                                          <input
                                            type="number"
                                            {...register(
                                              `fields.${index}.maintaxes.${taxIndex}.no`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]
                                                ?.maintaxes?.[taxIndex]?.no
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                        </td>
                                      ))}
                                      {field.taxes.map((tax, taxIndex) => (
                                        <td key={taxIndex}>
                                          <input
                                            type="number"
                                            {...register(
                                              `fields.${index}.taxes.${taxIndex}.no`,
                                              { required: true }
                                            )}
                                            className={`form-control ${
                                              errors?.fields?.[index]?.taxes?.[
                                                taxIndex
                                              ]?.no
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                          />
                                        </td>
                                      ))}
                                      <td>
                                        <Controller
                                          name={`fields.${index}.usage_type_id`}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              options={usagetype}
                                              className={`custom-select ${
                                                errors?.fields?.[index]
                                                  ?.usage_type_id
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              onChange={(selectedOption) => {
                                                field.onChange(selectedOption);
                                                GetAllUsageSubType(
                                                  selectedOption.value
                                                );
                                                setValue(
                                                  `fields.${index}.usage_subtype_id`,
                                                  null
                                                ); // Reset usage subtype on type change
                                              }}
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          name={`fields.${index}.usage_subtype_id`}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              {...field}
                                              options={usagesubtype}
                                              className={`custom-select ${
                                                errors?.fields?.[index]
                                                  ?.usage_subtype_id
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              onChange={(selectedOption) =>
                                                field.onChange(selectedOption)
                                              }
                                            />
                                          )}
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-small"
                                          onClick={() => removeField(index)}
                                        >
                                          &times;
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan="4" className="text-right">
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={addNewField}
                                      >
                                        + Add Row
                                      </button>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </form>
                            {/* <table className="table table-bordered align-items-center table-sm mt-5">
                              <thead className="thead-light">
                                <tr>
                                  <th>No</th>
                                  <th>Taxes</th>
                                  {taxes?.length &&
                                    taxes?.map((tax) => <th>{tax?.name}</th>)}
                                  <th>Usage Type</th>
                                  <th>Usage SubType</th>
                                  <th>Remove</th>
                                </tr>
                                <tr>
                                  <th></th>
                                  <th>Ratable Value</th>

                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields?.map((field, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <input
                                        type="number"
                                        name="ratable_value_start[]"
                                        className="form-control"
                                        value={field.ratable_value_start}
                                        required
                                        style={
                                          errors[`ratable_value_start${index}`]
                                            ? errorBoarderStyle
                                            : {}
                                        }
                                        onChange={(e) => {
                                          const updatedFields = [...fields];
                                          updatedFields[
                                            index
                                          ].ratable_value_start =
                                            e.target.value;
                                          setFields(updatedFields);
                                        }}
                                      />
                                      To
                                      <input
                                        type="number"
                                        name="ratable_value_end[]"
                                        className="form-control"
                                        style={
                                          errors[`ratable_value_end${index}`]
                                            ? errorBoarderStyle
                                            : {}
                                        }
                                        required
                                        value={field.ratable_value_end}
                                        onChange={(e) => {
                                          const updatedFields = [...fields];
                                          updatedFields[
                                            index
                                          ].ratable_value_end = e.target.value;
                                          setFields(updatedFields);
                                        }}
                                      />
                                    </td>

                                    {field?.taxes?.map((tax, Taxindex) => (
                                      <td>
                                        <input
                                          type="number"
                                          name="tax[]"
                                          className="form-control"
                                          required
                                          style={
                                            errors[`tax${index}${Taxindex}`]
                                              ? errorBoarderStyle
                                              : {}
                                          }
                                          defaultValue={tax.no}
                                          onChange={(e) => {
                                            const updatedFields = [...fields];
                                            updatedFields[index].taxes[
                                              Taxindex
                                            ].no = Number(e.target.value);
                                            setFields(updatedFields);
                                          }}
                                        />
                                      </td>
                                    ))}

                                    <td style={relatable}>
                                      <Select
                                        name={`usage_type_id${index}`}
                                        className="custom-select"
                                        value={field?.usage_type_id}
                                        style={
                                          errors[`usage_type_id${index}`]
                                            ? errorBoarderStyle
                                            : {}
                                        }
                                        onFocus={() => {
                                          handleClicks("usage_type_id", index);
                                        }}
                                        options={usagetype}
                                        onChange={(e) => {
                                          const updatedFields = [...fields];
                                          updatedFields[index].usage_type_id =
                                            e;
                                          setFields(updatedFields);
                                        }}
                                      />
                                    </td>
                                    <td style={relatable}>
                                      <Select
                                        name={`usage_subtype_id${index}`}
                                        className="custom-select"
                                        value={field?.usage_subtype_id}
                                        style={
                                          errors[`usage_subtype_id${index}`]
                                            ? errorBoarderStyle
                                            : {}
                                        }
                                        onFocus={() => {
                                          handleClicks(
                                            "usage_subtype_id",
                                            field?.usage_type_id?.value
                                          );
                                        }}
                                        options={usagesubtype}
                                        onChange={(e) => {
                                          const updatedFields = [...fields];
                                          updatedFields[
                                            index
                                          ].usage_subtype_id = e;
                                          setFields(updatedFields);
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-small"
                                        onClick={() => removeField(index)}
                                      >
                                        &times;
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan="4" className="text-right">
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                      onClick={addNewField}
                                    >
                                      + Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table> */}
                          </div>

                          <div className="row  mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <Link to="/master/ratablevalueslist">
                                <button
                                  type="button"
                                  className="btn btn-cancel me-2"
                                >
                                  {" "}
                                  <img
                                    // src={cancel}
                                    className="cancel-img"
                                    alt=""
                                  />{" "}
                                  Cancel
                                </button>
                              </Link>
                              <button
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                className="btn btn-save"
                              >
                                {" "}
                                <img
                                  // src={save}
                                  className="save-img me-2"
                                  alt=""
                                />
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Ratable Value Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      {/* <div className="save-modal">
        <div
          // className={`modal fade ${showErrorModal ? "show" : ""}`}
          // style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  {/* <img src={circle} className="circle-img mb-2" alt="" /> *
                </div>
                <h1 className="add-success text-center">
                  Agency Name Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Add;
