import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";

const AddCity = () => {
  const { postData, getData, minLength, maxLength } = useContext(Context);

  const navigate = useNavigate();

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [taluka, setTaluka] = useState([]);

  const [formData, setFormData] = useState({
    state: "",
    country: "",
    district: "",
    taluka: "",
    count: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    // const regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    // const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    // if (!formData.city) {
    //   errors.city = "city is required";
    // } else if (!regex.test(formData.city.trim())) {
    //   errors.city = "enter valid city name";
    // } else if (
    //   formData.city.trim().length < minLength ||
    //   formData.city.trim().length > maxLength
    // ) {
    //   errors.city = `city name must be between ${minLength} and ${maxLength} characters`;
    // }

    // const regex = /^[\p{L}\p{M}0-9]+(\s[\p{L}\p{M}0-9]+)*$/u;

    countLang.forEach((field) => {
      // Check if the field with the current id is missing in the formData object
      if (!formData[field.id]) {
        // If missing, set an error message for that field in the errors object
        errors[field.id] = "city name is required";
      } else {
        // If the field is present in formData, perform additional validations
        const trimmedName = formData[field.id].trim();

        // if (!regex.test(trimmedName)) {
        //   errors[field.id] = "Enter a valid city name";
        // } else
        if (trimmedName.length < minLength || trimmedName.length > maxLength) {
          errors[
            field.id
          ] = `city name must be between ${minLength} and ${maxLength} characters`;
        }
      }
    });

    if (!formData.state) {
      errors.state = "state is required";
    }

    if (!formData.district) {
      errors.district = "district is required";
    }

    if (!formData.taluka) {
      errors.city = "city is required";
    }
    if (!formData.country) {
      errors.country = "country is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    if (e.target.name === "country") {
      setState([]);
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setFormData((prev) => {
        return { ...prev, state: "" };
      });
      getSelectedState(e.target.value);
      return;
    }
    if (e.target.name === "state") {
      // setZone([]);
      getSelectedDistrict(formData.country, e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        // zone: "",
        district: "",
      });
      return;
    }

    if (e.target.name === "district") {
      // setZone([]);
      getSelectedTaluka(formData.country, formData.state, e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        // zone: "",
        // district: "",
      });
      return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        setErrors({});
        const response = await postData(
          "/master/taskmaster/city/postcity",
          formData
        );
        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/city");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/city`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  async function getSelectedState(id) {
    const response = await getData(
      `/master/taskmaster/state/getPerticularstate?country=${id}`
    );
    setState(response?.data?.state);
  }

  async function getSelectedDistrict(country_id, state_id) {
    const response = await getData(
      `/master/taskmaster/district/getPerticulardistrict?country=${country_id}&state=${state_id}`
    );

    setDistrict(response.data.district);
  }

  async function getSelectedTaluka(country_id, state_id, district_id) {
    const response = await getData(
      `/master/taskmaster/taluka/getperticulartaluka?country=${country_id}&state=${state_id}&district=${district_id}`
    );

    // console.log(response);

    setTaluka(response.data.taluka);
  }

  const GetAllCountry = async () => {
    const response = await getData("/master/taskmaster/country/allcountry");
    setCountry(response);
  };

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    const response = await getData(`/api/getlanguages`);
    setCountLang(response.data.data);
    setFormData({ ...formData, count: response.data.data });
  };

  useEffect(() => {
    GetAllCountry();
    getLanguges();
  }, []);

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/city">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-5 ">
                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Country Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="country"
                                        className="form-control"
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          select country
                                        </option>
                                        {country?.data?.country?.map(
                                          (ele, index) => {
                                            return (
                                              <option value={ele.id}>
                                                {ele.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>

                                      {errors.country && (
                                        <span style={errorStyle}>
                                          {errors?.country}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      State Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="state"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.state}
                                      >
                                        <option value="" hidden>
                                          select state
                                        </option>
                                        {state?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.state && (
                                        <span style={errorStyle}>
                                          {errors?.state}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div class="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      District Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="district"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.district}
                                      >
                                        <option value="" hidden>
                                          select district
                                        </option>
                                        {district?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.district && (
                                        <span style={errorStyle}>
                                          {errors?.district}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div class="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Taluka Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="taluka"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.taluka}
                                      >
                                        <option value="" hidden>
                                          select taluka
                                        </option>
                                        {taluka?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.taluka && (
                                        <span style={errorStyle}>
                                          {errors?.taluka}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  {countLang?.map((data, index) => {
                                    return (
                                      <div
                                        className="row justify-content-center mb-2"
                                        key={index}
                                      >
                                        <label
                                          htmlFor="inputEmail3"
                                          className="col-sm-3 col-form-label"
                                        >
                                          City Name {data?.name}
                                        </label>
                                        <div className="col-sm-6">
                                          <input
                                            type="text"
                                            name={data.id}
                                            placeholder={`Enter city name in ${data?.name}`}
                                            value={formData.city}
                                            onChange={handleChange}
                                            className="form-control"
                                            id="inputEmail3"
                                          />

                                          {errors[data.id] && (
                                            <span style={errorStyle}>
                                              {errors[data.id] +
                                                " in " +
                                                data.name}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/city">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save"
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  City Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">City Already Exits</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCity;
