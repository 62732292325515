import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";

const EditZone = () => {
  const { postData, getData, getEditData, editData, minLength, maxLength } =
    useContext(Context);
  const { id } = useParams();

  const navigate = useNavigate();

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [taluka, setTaluka] = useState([]);
  const [city, setCity] = useState([]);
  const [ward, setWard] = useState([]);

  // const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    country: "",
    state: "",
    district: "",
    taluka: "",
    ward: "",
    city: "",
    sequence_start: "",
    id: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};
    // const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;

    // if (!formData.zone) {
    //   errors.zone = "zone is required";
    // } else if (!regex.test(formData.zone.trim())) {
    //   errors.zone = "Enter Valid zone Name";
    // } else if (
    //   formData.zone.trim().length < minLength ||
    //   formData.zone.trim().length > maxLength
    // ) {
    //   errors.zone = `zone name must be between ${minLength} and ${maxLength} characters`;
    // }

    const regex = /^[\p{L}\p{M}0-9]+(\s[\p{L}\p{M}0-9]+)*$/u;

    countLang.forEach((field) => {
      // Check if the field with the current id is missing in the formData object
      if (!formData[field.id]) {
        // If missing, set an error message for that field in the errors object
        errors[field.id] = "zone is required";
      } else {
        // If the field is present in formData, perform additional validations
        const trimmedName = formData[field.id].trim();

        // if (!regex.test(trimmedName)) {
        //   errors[field.id] = "Enter a valid zone";
        // } else
        if (trimmedName.length < minLength || trimmedName.length > maxLength) {
          errors[
            field.id
          ] = `zone must be between ${minLength} and ${maxLength} characters`;
        }
      }
    });

    if (!formData.country) {
      errors.country = "country is required";
    }
    if (!formData.state) {
      errors.state = "state is required";
    }
    if (!formData.district) {
      errors.district = "district is required";
    }
    if (!formData.taluka) {
      errors.taluka = "taluka is required";
    }
    if (!formData.city) {
      errors.city = "city is required";
    }
    if (!formData.ward) {
      errors.ward = "ward is required";
    }
    if (!formData.sequence_start) {
      errors.sequence_start = "sequence start is required";
    }
    return errors;
  };

  // console.log(formData);

  const handleChange = (e) => {
    if (e.target.name === "country") {
      setState([]);
      setDistrict([]);
      setTaluka([]);
      setCity([]);
      setWard([]);
      // getSelectedState(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        state: "",
        district: "",
        taluka: "",
        city: "",
        ward: "",
        zone: "",
      });
      return;
    }
    if (e.target.name === "state") {
      setDistrict([]);
      setTaluka([]);
      setCity([]);
      setWard([]);
      // getSelectedDistrict(formData.country, e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        district: "",
        taluka: "",
        city: "",
        ward: "",
        // zone: "",
      });
      return;
    }

    if (e.target.name === "district") {
      setTaluka([]);
      setCity([]);
      setWard([]);
      // getSelectedTaluka(formData.country, formData.state, e.target.value);
      // getSelectedBlock(formData.country, formData.state, e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        taluka: "",
        city: "",
        ward: "",
        // zone: "",
      });
      return;
    }

    if (e.target.name === "taluka") {
      setCity([]);
      setWard([]);
      // getSelectedCity(
      //   formData.country,
      //   formData.state,
      //   formData.district,
      //   e.target.value
      // );
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        city: "",
        ward: "",
        // zone: "",
      });
      return;
    }

    if (e.target.name === "city") {
      setWard([]);
      // getSelectedWard(
      //   formData.country,
      //   formData.state,
      //   formData.district,
      //   formData.taluka,
      //   e.target.value
      // );
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        ward: "",
        // zone: "",
      });
      return;
    }

    // if (e.target.name === "city") {
    //   setDistrict([]);
    //   setCity([]);
    //   getSelectedCity(
    //     formData.country,
    //     formData.state,
    //     formData.district,
    //     e.target.value
    //   );
    //   setFormData({
    //     ...formData,
    //     [e.target.name]: e.target.value,
    //     district: "",
    //     city: "",
    //   });
    //   return;
    // }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //get all country........................................................................
  useEffect(() => {
    const GetAllCountry = async () => {
      const response = await getData("/master/taskmaster/country/allcountry");
      const data = await response?.data?.country;
      setCountry(data);
    };

    GetAllCountry();
  }, []);

  //state..............................................................................
  useEffect(() => {
    if (formData.country) {
      async function getState() {
        const response = await getData(
          `/master/taskmaster/state/getperticularstate?country=${formData.country}`
        );
        const data = await response?.data?.state;
        setState(data);
      }
      getState();
    }
  }, [formData.country]);

  //district.........................................................................
  useEffect(() => {
    if (formData.state && formData.country) {
      async function getDistrict() {
        const response = await getData(
          `/master/taskmaster/district/getperticulardistrict?country=${formData.country}&state=${formData.state}`
        );
        const district = await response?.data?.district;
        // console.log("  Ditrict  " + JSON.stringify(district));
        setDistrict(district);
      }
      getDistrict();
    }
  }, [formData.state]);

  //City.........................................................................
  useEffect(() => {
    if (formData.state && formData.country && formData.district) {
      async function getCity() {
        const response = await getData(
          `/master/taskmaster/taluka/getperticulartaluka?country=${formData.country}&state=${formData.state}&district=${formData.district}`
        );
        const district = await response?.data?.taluka;
        // console.log("  Ditrict  " + JSON.stringify(district));
        setTaluka(district);
      }
      getCity();
    }
  }, [formData.district]);

  //Zone.........................................................................
  useEffect(() => {
    if (
      formData.state &&
      formData.country &&
      formData.district &&
      formData.taluka
    ) {
      async function getZone() {
        const response = await getData(
          `/master/taskmaster/city/getperticularcity?country=${formData.country}&state=${formData.state}&taluka=${formData.taluka}&district=${formData.district}`
        );
        const district = await response?.data?.city;
        // console.log("  fjksdfjksd  " + JSON.stringify(district));
        setCity(district);
      }
      getZone();
    }
  }, [formData.taluka]);

  //Zone.........................................................................
  useEffect(() => {
    if (
      formData.state &&
      formData.country &&
      formData.district &&
      formData.taluka &&
      formData.city
    ) {
      async function getBlock() {
        const response = await getData(
          `/master/taskmaster/ward/getperticularward?country=${formData.country}&state=${formData.state}&city=${formData.city}&district=${formData.district}&taluka=${formData.taluka}`
        );
        const district = await response?.data?.ward;
        // console.log("  fjksdfjksd  " + JSON.stringify(district));
        setWard(district);
      }
      getBlock();
    }
  }, [formData.city]);

  //Get Edit DATA ........................................................................
  const GetEditData = async () => {
    const response = await getEditData(
      `/master/taskmaster/zone/geteditzone/${id}`
    );

    // console.log(response);
    const district = await response?.data.zone;
    setFormData(response?.data.langList);
    await setFormData((prevFormData) => ({
      ...prevFormData,
      country: district?.country_id,
      state: district?.state_id,
      district: district?.district_id,
      city: district?.city_id,
      taluka: district?.taluka_id,
      ward: district?.ward_id,
      id: district?.id,
      sequence_start: district?.sequence_start,
    }));
    getLanguges();
  };

  const [countLang, setCountLang] = useState([]);

  const getLanguges = async () => {
    const response = await getData(`/api/getlanguages`);
    await setCountLang(response.data.data);
  };
  useEffect(() => {
    GetEditData();
  }, [1]);

  //Get Edit DATA .......................................................

  //submit handler.........................................
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (isLoading) {
    //   return;
    // }
    // setIsLoading(true);
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await editData(
          `/master/taskmaster/zone/editzone/${id}`,
          formData
        );

        const success = await response.success;
        if (success) {
          setShowModal(true);
          // setIsLoading(false);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/zone");
          }, 1000);
        } else {
          setShowErrorModal(true);
          // setIsLoading(false);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/zone/edit/${id}`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };
  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/zone">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-5 ">
                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Country Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="country"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.country}
                                      >
                                        <option value="" hidden>
                                          select country
                                        </option>
                                        {country?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.country && (
                                        <span style={errorStyle}>
                                          {errors?.country}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      State Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="state"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.state}
                                      >
                                        <option value="" hidden>
                                          select state
                                        </option>
                                        {state?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.state && (
                                        <span style={errorStyle}>
                                          {errors?.state}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      District Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="district"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.district}
                                      >
                                        <option value="" hidden>
                                          select district
                                        </option>
                                        {district?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.district && (
                                        <span style={errorStyle}>
                                          {errors?.district}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Taluka Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="taluka"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.taluka}
                                      >
                                        <option value="" hidden>
                                          select taluka
                                        </option>
                                        {taluka?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.taluka && (
                                        <span style={errorStyle}>
                                          {errors?.taluka}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      City Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="city"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData?.city}
                                      >
                                        <option value="" hidden>
                                          select city
                                        </option>
                                        {city?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.city && (
                                        <span style={errorStyle}>
                                          {errors?.city}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Ward Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="ward"
                                        className="form-control"
                                        value={formData?.ward}
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          select ward
                                        </option>
                                        {ward?.map((ele, index) => {
                                          return (
                                            <option value={ele.id} key={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.ward && (
                                        <span style={errorStyle}>
                                          {errors?.ward}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  {countLang?.map((data, index) => {
                                    return (
                                      <div
                                        className="row justify-content-center mb-2"
                                        key={index}
                                      >
                                        <label
                                          htmlFor="inputEmail3"
                                          className="col-sm-3 col-form-label"
                                        >
                                          Zone {data?.name}
                                        </label>
                                        <div className="col-sm-6">
                                          <input
                                            type="text"
                                            name={data.id}
                                            value={formData[data.id]}
                                            placeholder={`Enter zone name in ${data?.name}`}
                                            onChange={handleChange}
                                            className="form-control"
                                            id="inputEmail3"
                                          />
                                          {errors[data.id] && (
                                            <span style={errorStyle}>
                                              {errors[data.id] +
                                                " in " +
                                                data.name}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Sequence Start
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="text"
                                        name="sequence_start"
                                        placeholder={`Enter sequence start`}
                                        value={formData.sequence_start}
                                        onChange={handleChange}
                                        className="form-control"
                                        id="inputEmail3"
                                      />

                                      {errors.sequence_start && (
                                        <span style={errorStyle}>
                                          {errors.sequence_start}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/zone">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save me-2"
                                  // disabled={isLoading}
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Zone Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">Zone Already Exits</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditZone;
