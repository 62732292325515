import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../layout/Sidebar";
import { Context } from "../../../../../utils/context";

const Add = () => {
  const { getEditData, editData, minLength, maxLength, getData } =
    useContext(Context);
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    name: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    const regex = /^\d+/;

    if (!formData.name) {
      errors.name = "standard rate is required";
    } else if (!regex.test(formData.name.trim())) {
      errors.name = "enter valid standard rate";
    }

    if (!formData.discount) {
      errors.discount = "discount percentage is required";
    } else if (!regex.test(formData.discount.trim())) {
      errors.discount = "enter valid discount percentage";
    }

    // const regex = /^[\p{L}\p{M}0-9]+(\s[\p{L}\p{M}0-9]+)*$/u;

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log([e.target.name] + "=" + e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        setErrors({});
        const response = await editData(
          "/master/taskmaster/standard_rate/editstandard_rate",
          formData
        );

        // console.log(response);
        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate(`/tax-master/standard_rate/${id}`);
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/standard_rate/${id}`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getEditData(
      `/master/taskmaster/standard_rate/geteditstandard_rate/${id}`
    );
    setData(response);

    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  d-flex me-4">
                        <Link to="/tax-master/languages">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>

                        <div className="heading-holder text-center ">
                          <h1 style={{ marginLeft: "230px", fontSize: "30px" }}>
                            Standard Rate
                          </h1>
                        </div>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-5 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Standard Rate{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        placeholder="Enter Standard Rate"
                                        onChange={handleChange}
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.name && (
                                        <span style={errorStyle}>
                                          {errors.name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="main-form-section mt-5 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Discount Percentage{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="text"
                                        name="discount"
                                        value={formData.discount}
                                        placeholder="Enter Discount Percentage"
                                        onChange={handleChange}
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.discount && (
                                        <span style={errorStyle}>
                                          {errors.discount}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/languages">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button type="submit" className="btn btn-save">
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Standrard Rate Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Standrard Rate Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
