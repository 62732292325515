import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "../Tax.css";
import circle from "../../../assets/icons/circle.png";
import rigth from "../../../assets/icons/rigth.png";
import save from "../../../assets/icons/save.png";
import cancel from "../../../assets/icons/cross.png";
import Sidebar from "../../../Master/layout/Sidebar";
import { Context } from "../../../../../utils/context";

const AddPincode = () => {
  const { postData, getData } = useContext(Context);

  const navigate = useNavigate();

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const [formData, setFormData] = useState({
    country: "",
    state: "",
    district: "",
    city: "",
    zone: "",
    block: "",
    ward: "",
    pincode: "",
  });

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [zone, setZone] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [block, setBlock] = useState([]);
  const [ward, setWard] = useState([]);

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};
    const regex = /^\d{6}$/;

    if (!formData.pincode) {
      errors.pincode = "pincode is required";
    } else if (!regex.test(formData.pincode)) {
      errors.pincode = "enter valid pincode and accepts only 6 digits";
    }

    if (!formData.country) {
      errors.country = "country is required";
    }
    if (!formData.state) {
      errors.state = "state is required";
    }
    if (!formData.zone) {
      errors.zone = "zone is required";
    }
    if (!formData.district) {
      errors.district = "district is required";
    }
    if (!formData.city) {
      errors.city = "city is required";
    }
    if (!formData.block) {
      errors.block = "block is required";
    }
    if (!formData.ward) {
      errors.ward = "ward is required";
    }

    return errors;
  };

  async function getSelectedState(id) {
    const response = await getData(
      `/master/taskmaster/state/getPerticularstate?country=${id}`
    );
    const data = await response.data.state[0].states;
    setState(data);
  }

  async function getSelectedDistrict(country_id, state_id) {
    const response = await getData(
      `/master/taskmaster/district/getperticulardistrict?country=${country_id}&state=${state_id}`
    );
    const data = await response.data.district;
    setDistrict(data);
  }

  async function getSelectedCity(country_id, state_id, district_id) {
    const response = await getData(
      `/master/taskmaster/city/getperticularcity?country=${country_id}&state=${state_id}&district=${district_id}`
    );
    const data = await response.data.city;
    setCity(data);
  }

  async function getSelectedZone(country_id, state_id, district_id, city_id) {
    const response = await getData(
      `/master/taskmaster/zone/getperticularzone?country=${country_id}&state=${state_id}&city=${city_id}&district=${district_id}`
    );

    const data = await response.data.zone;
    //console.log(data)
    setZone(data);
  }

  async function getSelectedBlock(
    country_id,
    state_id,
    district_id,
    city_id,
    zone_id
  ) {
    const response = await getData(
      `/master/taskmaster/block/getperticularblock?country=${country_id}&state=${state_id}&city=${city_id}&district=${district_id}&zone=${zone_id}`
    );

    const data = await response.data.block;
    // console.log(data);
    setBlock(data);
  }

  async function getSelectedWard(
    country_id,
    state_id,
    district_id,
    city_id,
    zone_id,
    block_id
  ) {
    const response = await getData(
      `/master/taskmaster/ward/getperticularward?country=${country_id}&state=${state_id}&city=${city_id}&district=${district_id}&zone=${zone_id}&block=${block_id}`
    );

    const data = await response.data.ward;
  
    setWard(data);
  }

  const GetAllCountry = async () => {
    const response = await getData("/master/taskmaster/country/allcountry");

    setCountry(response.data.country);
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "country") {
      setState([]);
      setDistrict([]);
      setCity([]);
      setZone([]);
      setBlock([]);
      setWard([]);
      getSelectedState(e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        state: "",
        district: "",
        city: "",
        zone: "",
        block: "",
        ward: "",
      });
      return;
    }
    if (e.target.name === "state") {
      setDistrict([]);
      setCity([]);
      setZone([]);
      setBlock([]);
      setWard([]);
      getSelectedDistrict(formData.country, e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        district: "",
        city: "",
        zone: "",
        block: "",
        ward: "",
      });
      return;
    }

    if (e.target.name === "district") {
      // setCity([]);
      setCity([]);
      setZone([]);
      setBlock([]);
      setWard([]);
      getSelectedCity(formData.country, formData.state, e.target.value);
      // getSelectedBlock(formData.country, formData.state, e.target.value);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        city: "",
        zone: "",
        block: "",
        ward: "",
      });
      return;
    }

    if (e.target.name === "city") {
      setZone([]);
      setBlock([]);
      setWard([]);
      getSelectedZone(
        formData.country,
        formData.state,
        formData.district,
        e.target.value
      );
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        zone: "",
        block: "",
        ward: "",
      });
      return;
    }

    if (e.target.name === "zone") {
      setBlock([]);
      setWard([]);
      getSelectedBlock(
        formData.country,
        formData.state,
        formData.district,
        formData.city,
        e.target.value
      );
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        block: "",
        ward: "",
      });
      return;
    }

    if (e.target.name === "block") {
      setWard([]);
      getSelectedWard(
        formData.country,
        formData.state,
        formData.district,
        formData.city,
        formData.zone,
        e.target.value
      );
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,

        ward: "",
      });
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        setErrors({});

        const response = await postData(
          "/master/taskmaster/pincode/postpincode",
          formData
        );
        const result = await response.success;

        if (result) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/tax-master/pincode");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/tax-master/pincode`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/pincode">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-5 ">
                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Country Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="country"
                                        className="form-control"
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          select country
                                        </option>
                                        {country?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>

                                      {errors.country && (
                                        <span style={errorStyle}>
                                          {errors?.country}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      State Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="state"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.state}
                                      >
                                        <option value="" hidden>
                                          select state
                                        </option>
                                        {state?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.state && (
                                        <span style={errorStyle}>
                                          {errors?.state}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      District Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="district"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.district}
                                      >
                                        <option value="" hidden>
                                          select district
                                        </option>
                                        {district?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.district && (
                                        <span style={errorStyle}>
                                          {errors?.district}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      City Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="city"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.city}
                                      >
                                        <option value="" hidden>
                                          select city
                                        </option>
                                        {city?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.city && (
                                        <span style={errorStyle}>
                                          {errors?.city}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Zone Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="zone"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.zone}
                                      >
                                        <option value="" hidden>
                                          select zone
                                        </option>
                                        {zone?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.zone && (
                                        <span style={errorStyle}>
                                          {errors?.zone}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Block Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="block"
                                        className="form-control"
                                        value={formData.block}
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          select block
                                        </option>
                                        {block?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.block && (
                                        <span style={errorStyle}>
                                          {errors?.block}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      {" "}
                                      Ward Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        name="ward"
                                        className="form-control"
                                        value={formData.ward}
                                        onChange={handleChange}
                                      >
                                        <option value="" hidden>
                                          select ward
                                        </option>
                                        {ward?.map((ele, index) => {
                                          return (
                                            <option value={ele.id}>
                                              {ele.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {errors.ward && (
                                        <span style={errorStyle}>
                                          {errors?.ward}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Pincode{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="tel"
                                        name="pincode"
                                        value={formData.pincode}
                                        onChange={handleChange}
                                        placeholder="Enter Pincode"
                                        className="form-control"
                                        maxLength="6"
                                        id="inputEmail3"
                                      />

                                      {errors.pincode && (
                                        <span style={errorStyle}>
                                          {errors?.pincode}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/tax-master/pincode">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>

                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save"
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Pincode Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Pincode Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPincode;
