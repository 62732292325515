import axios from "axios";
import Cookies from "js-cookie";
import i18n from "i18next";
const token = Cookies.get("billing_security");

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  },
});

axiosClient.interceptors.request.use(
  async (config) => {
    try {
      const token = Cookies.get("billing_security");

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      } else {
        config.headers["Authorization"] = "";
      }
      config.headers["Languageid"] = i18n.language;
      // console.log(i18n.language);
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axiosClient;

// axiosClient.interceptors.request.use(
//   async (config) => {
//     const token = Cookies.get("billing_security");
//     const [t, i18n] = await useTranslation("global");
//     // console.log(token);
//     config.headers["language"] = i18n.language;
//     if (token) {
//       config.headers["Authorization"] = "Bearer " + token;
//     } else {
//       config.headers["Authorization"] = "";
//     }

//     return config;
//   },
//   (err) => {
//     Promise.reject(err);
//   }
// );

// import axios from "axios";
// import Cookies from "js-cookie";

// const token = Cookies.get("billing_security");

// const axiosClient = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
//   headers: {
//     Authorization: `Bearer ${token}`,
//     Accept: "application/json",
//     "Content-Type": "application/json",
//   },
// });

// axiosClient.interceptors.request.use(
//   (config) => {
//     const token = Cookies.get("billing_security");
//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (err) => {
//     return Promise.reject(err);
//   }
// );

// export default axiosClient;
